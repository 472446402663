import styled from "@emotion/styled"
import React from "react"

import Layout from "../components/Layout"
import PaletteColor from "../components/PaletteColor"
import SEO from "../components/SEO"
import mq from "../utils/mediaQuery"

const Container = styled.div`
  ${mq({
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    margins: "16px",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  })};
`

const H1 = styled.h1`
  ${mq({
    width: "100%",
    fontSize: [16, 24, 24],
    fontWeight: 500,
    marginTop: ["4px", "8px", "8px"],
    marginBottom: ["8px", "16px", "16px"],
  })};
`

const Gallery = () => {
  const content = []
  for (let i = 0; i < 256; i++) {
    content.push(<PaletteColor key={i} colorNumber={i} />)
  }
  return (
    <>
      <SEO title="cryptohexies" />
      <Layout title="cryptohexies">
        <H1>cryptohexies color palette</H1>
        <Container>{content}</Container>
      </Layout>
    </>
  )
}

export default Gallery
