import styled from "@emotion/styled"
import React from "react"

import colorPalette from "../colorPalette"
import mq from "../utils/mediaQuery"

const ColorContainer = styled.div`
  ${mq({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: ["12px", "20px", "28px"],
  })};
`

const ColorSquare = styled.div`
  ${mq({
    width: "85%",
    paddingTop: "85%",
    backgroundColor: "#49F9F9",
    borderRadius: "10px",
    marginTop: ["16px", "22px", "22px"],
  })};
`
const NameSection = styled.h1`
  ${mq({
    width: "100%",
    fontSize: ["04px", "06px", "08px"],
    marginTop: ["03px", "06px", "08px"],
    marginBottom: ["06px", "08px", "08px"],
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "#CDCDCD",
  })};
`

export default props => {
  const { colorNumber } = props
  const hexString = colorNumber.toString(16).toUpperCase().padStart(2, "0")
  const cssString = colorPalette[colorNumber]
  return (
    <ColorContainer>
      <ColorSquare style={{ backgroundColor: cssString }} />
      <NameSection style={{ color: cssString }}>0x{hexString}</NameSection>
    </ColorContainer>
  )
}
