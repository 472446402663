export default [
  "#007f7f",
  "#3fbfbf",
  "#00ffff",
  "#bfffff",
  "#8181ff",
  "#0000ff",
  "#3f3fbf",
  "#00007f",
  "#0f0f50",
  "#7f007f",
  "#bf3fbf",
  "#f500f5",
  "#fd81ff",
  "#ffc0cb",
  "#ff8181",
  "#ff0000",
  "#bf3f3f",
  "#7f0000",
  "#551414",
  "#7f3f00",
  "#bf7f3f",
  "#ff7f00",
  "#ffbf81",
  "#ffffbf",
  "#ffff00",
  "#bfbf3f",
  "#7f7f00",
  "#007f00",
  "#3fbf3f",
  "#00ff00",
  "#afffaf",
  "#00bfff",
  "#007fff",
  "#4b7dc8",
  "#bcafc0",
  "#cbaa89",
  "#a6a090",
  "#7e9494",
  "#6e8287",
  "#7e6e60",
  "#a0695f",
  "#c07872",
  "#d08a74",
  "#e19b7d",
  "#ebaa8c",
  "#f5b99b",
  "#f6c8af",
  "#f5e1d2",
  "#7f00ff",
  "#573b3b",
  "#73413c",
  "#8e5555",
  "#ab7373",
  "#c78f8f",
  "#e3abab",
  "#f8d2da",
  "#e3c7ab",
  "#c49e73",
  "#8f7357",
  "#73573b",
  "#3b2d1f",
  "#414123",
  "#73733b",
  "#8f8f57",
  "#a2a255",
  "#b5b572",
  "#c7c78f",
  "#dadaab",
  "#ededc7",
  "#c7e3ab",
  "#abc78f",
  "#8ebe55",
  "#738f57",
  "#587d3e",
  "#465032",
  "#191e0f",
  "#235037",
  "#3b573b",
  "#506450",
  "#3b7349",
  "#578f57",
  "#73ab73",
  "#64c082",
  "#8fc78f",
  "#a2d8a2",
  "#e1f8fa",
  "#b4eeca",
  "#abe3c5",
  "#87b48e",
  "#507d5f",
  "#0f6946",
  "#1e2d23",
  "#234146",
  "#3b7373",
  "#64abab",
  "#8fc7c7",
  "#abe3e3",
  "#c7f1f1",
  "#bed2f0",
  "#abc7e3",
  "#a8b9dc",
  "#8fabc7",
  "#578fc7",
  "#57738f",
  "#3b5773",
  "#0f192d",
  "#1f1f3b",
  "#3b3b57",
  "#494973",
  "#57578f",
  "#736eaa",
  "#7676ca",
  "#8f8fc7",
  "#ababe3",
  "#d0daf8",
  "#e3e3ff",
  "#ab8fc7",
  "#8f57c7",
  "#73578f",
  "#573b73",
  "#3c233c",
  "#463246",
  "#724072",
  "#8f578f",
  "#ab57ab",
  "#ab73ab",
  "#ebace1",
  "#ffdcf5",
  "#e3c7e3",
  "#e1b9d2",
  "#d7a0be",
  "#c78fb9",
  "#c87da0",
  "#c35a91",
  "#4b2837",
  "#321623",
  "#280a1e",
  "#401811",
  "#621800",
  "#a5140a",
  "#da2010",
  "#d5524a",
  "#ff3c0a",
  "#f55a32",
  "#ff6262",
  "#f6bd31",
  "#ffa53c",
  "#d79b0f",
  "#da6e0a",
  "#b45a00",
  "#a04b05",
  "#5f3214",
  "#53500a",
  "#626200",
  "#8c805a",
  "#ac9400",
  "#b1b10a",
  "#e6d55a",
  "#ffd510",
  "#ffea4a",
  "#c8ff41",
  "#9bf046",
  "#96dc19",
  "#73c805",
  "#6aa805",
  "#3c6e14",
  "#283405",
  "#204608",
  "#0c5c0c",
  "#149605",
  "#0ad70a",
  "#14e60a",
  "#7dff73",
  "#4bf05a",
  "#00c514",
  "#05b450",
  "#1c8c4e",
  "#123832",
  "#129880",
  "#06c491",
  "#00de6a",
  "#2deba8",
  "#3cfea5",
  "#6affcd",
  "#91ebff",
  "#55e6ff",
  "#7dd7f0",
  "#08ded5",
  "#109cde",
  "#055a5c",
  "#162c52",
  "#0f377d",
  "#004a9c",
  "#326496",
  "#0052f6",
  "#186abd",
  "#2378dc",
  "#699dc3",
  "#4aa4ff",
  "#90b0ff",
  "#5ac5ff",
  "#beb9fa",
  "#786ef0",
  "#4a5aff",
  "#6241f6",
  "#3c3cf5",
  "#101cda",
  "#0010bd",
  "#231094",
  "#0c2148",
  "#5010b0",
  "#6010d0",
  "#8732d2",
  "#9c41ff",
  "#bd62ff",
  "#b991ff",
  "#d7a5ff",
  "#d7c3fa",
  "#f8c6fc",
  "#e673ff",
  "#ff52ff",
  "#da20e0",
  "#bd29ff",
  "#bd10c5",
  "#8c14be",
  "#5a187b",
  "#641464",
  "#410062",
  "#320a46",
  "#551937",
  "#a01982",
  "#c80078",
  "#ff50bf",
  "#ff6ac5",
  "#faa0b9",
  "#fc3a8c",
  "#e61e78",
  "#bd1039",
  "#98344d",
  "#911437",
  "#000000",
  "#111111",
  "#222222",
  "#333333",
  "#444444",
  "#555555",
  "#666666",
  "#777777",
  "#888888",
  "#999999",
  "#aaaaaa",
  "#bbbbbb",
  "#cccccc",
  "#dddddd",
  "#eeeeee",
  "#ffffff",
]
